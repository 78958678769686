<template>
  <div>
    <div class="mt-20">
      <span class="reportRecord">报告记录</span>
<!--      <span class="break">刷新</span>-->
    </div>
    <el-table
        :data="shippingList"
        style="width: 100%">
      <el-table-column
          prop="type_word"
          label="报告类型"
          width="180">
      </el-table-column>
      <el-table-column
          prop="code"
          label="批编号"
          width="300">
      </el-table-column>
      <el-table-column
          width="400px"
          prop="coverTime"
          label="涵盖的日期范围">
      </el-table-column>
      <el-table-column
          prop="created_at"
          label="请求的日期和时间">
      </el-table-column>
      <el-table-column
          prop="updated_at"
          label="完成日期和时间">
      </el-table-column>
      <el-table-column
          width="100px"
          prop="status_word"
          label="报告状态">
      </el-table-column>
<!--      操作-->
      <el-table-column
          width="100px"
          fixed="right"
          label="操作"
          >
        <template slot-scope="{row}">
          <el-button @click="download(row)" type="text" size="small">下载</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props:{
    shippingList:{
      type:Array,
      default:()=>[]
    }
  },
  name: "bulkShippingList",
  data: () => ({}),
  mounted() {

  },
  methods: {
    download(row){
      console.log(row)
      window.location.href=row.download_url
    }
  },
  watch: {},

}
</script>

<style scoped lang="less">
.reportRecord {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.break {
  font-size: 14px;
  font-weight: 500;
  color: #FA4A14;
  line-height: 20px;
  margin-left: 20px;
}
/deep/.has-gutter {
  background: #F7F8FA;
  font-size: 15px;
  font-weight: 500;
  color: #333333;
  line-height: 21px;
}
/deep/.is-leaf {
  background: #F7F8FA;

}
/deep/.el-table__row {
  &:hover{
    background: #FFFFFF;
    box-shadow: 0 5px 12px 4px rgba(172, 172, 172, 0.40);
    margin-top: 2px;
  }
}
.mt-20 {
  margin-bottom: 20px;
}
</style>