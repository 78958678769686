<template>
  <div>
<!--    头部-->
    <order-report
    @changeShop="changeShop"
    />
<!--    list-->
    <bulk-shipping-list
    :shippingList="shippingList"
    />
<!--    分页-->
   <paging
   :meta="meta"
   @currentPage="currentPage"
   />
  </div>
</template>

<script>
import OrderReport from "@/views/order/bulkShipping/components/orderReport";
import BulkShippingList from "@/views/order/bulkShipping/components/bulkShippingList";
import Paging from "@/components/publicTab/index";
import {exportShipLogList} from "@/api/order";
export default {
  name: "index.vue",
  components: {Paging, BulkShippingList, OrderReport},
  data: () => ({
    shippingList:[],//发货列表
    meta:{},//分页
    page:1,
    perPage:10,
    shopCode:null ,// 店铺code
  }),
  mounted() {
    this.exportShipLogList()
  },
  methods: {
    //店铺变化时
    changeShop(e){
      this.shopCode = e
      this.exportShipLogList()

    },
    // 批量发货列表
    async exportShipLogList(){

      try {
        const data = await exportShipLogList({
          page:this.page,
          perPage:this.perPage,
          storeCode:this.shopCode
        })
        this.shippingList = data?.data?.list
        this.meta = data?.data?.meta

        this.shippingList.forEach(item=>{
          item.coverTime = item.start_time + '-' + item.end_time
        })
      }catch (e) {
        console.log(e)
        const {msg} = e
        this.$message.error(msg)
      }
    },
    // 分页
    currentPage(e){

      this.page = e
      this.exportShipLogList()
    }
  },
  watch: {},

}
</script>

<style scoped lang="less">

</style>