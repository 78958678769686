<template>
  <div class="top_box">
    <div>
         <div class="flex">
           <div class="chooseAStore">请选择店铺</div>
           <el-radio-group v-model="ruleForm.resource" v-if="shopSelectList.length">
             <el-radio v-for="item in shopSelectList" :key="item.id " :label="item.code">{{item.name}}</el-radio>
           </el-radio-group>
           <div v-else  class="addShop" @click="addShop">请添加店铺</div>
         </div>

      <div>
        <div class="orderReport_left">
          <div class="exportReport">批量导出配送订单报告</div>
          <div class="exportReport_text">1.  该文件主要用于亚马逊平台批量上传配送信息；</div>
          <div class="exportReport_text"> 2. 配送订单报告可列出您到今天为止的所有已发货但未完成订单；</div>
          <div class="exportReport_text">3. 该文件包含配送详情，如商品、数量、发货日期、承运人和追踪编码。您所提交的数据可使买家查看其订单状态；</div>
        </div>
      </div>
    </div>
    <div class="orderReport_right">
      <el-button type="primary" class="primary" @click="requestReport">请求报告</el-button>
    </div>
  </div>
</template>

<script>
import {shopSelect} from "@/api/productTemplate";
import {shipOrderExport} from "@/api/order";

export default {
  name: "orderReport",
  data: () => ({
    ruleForm:{
      resource:''
    },
    shopSelectList:[]
  }),
  mounted() {
    this.shopSelect()
  },
  methods: {
    // 前往添加店铺
    addShop(){
      this.$router.push({name:'managementStore'})
    },
    //店铺list
    async shopSelect(){
      try {
        const data = await shopSelect()
        this.shopSelectList = data?.data
        this.ruleForm.resource = this.shopSelectList[0].code || ''
      }catch (e) {
        console.log(e)
        const {msg} = e
        this.$message.error(msg)
      }
    },
    //请求报告
    async requestReport(){
        try {
          const {data,msg} = await shipOrderExport({
            storeCode:this.ruleForm.resource
          })
          window.location.href=data.info
          console.log(data.info)
        }catch (e) {
          console.log(e)
            const {msg } = e
          this.$message.error(msg)
        }
    }
  },
  watch: {
    // 监听店铺变化
    'ruleForm.resource':{
      handler(){
        this.$emit('changeShop',this.ruleForm.resource)
      }
    }
  },

}
</script>

<style scoped lang="less">
.addShop {
  font-size: 14px;
  cursor: pointer;
  color: #FA4A14;
  margin-bottom: 10px;
  margin-left: 20px;
}
.top_box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid  #E9E9E9;
  margin-bottom: 20px;
}
.exportReport {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  margin-bottom: 6px;
}
.exportReport_text {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}
.primary {
  width: 150px;
  height: 40px;
  background: #FA4A14;
  box-shadow: 0 0 10px 0 #F93A00;
  border-radius: 5px;
  font-size: 18px;
}
/deep/.el-radio-group {
  width:900px;

}
/deep/.el-radio {
  margin-bottom: 10px;
  margin-right:15px;
}
.chooseAStore{
  font-size: 14px;
  color: #606266;
  margin-right: 10px;

}
.flex {
  display: flex;
  line-height: 14px;
}
</style>